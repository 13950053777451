
 <template>
  <div>
    <v-form v-model="formValid" class="" ref="itemForm">
      <v-subheader class="font-italic font-weight-light py-1 "
        >Dati Generali</v-subheader
      >
      <v-row no-gutters class="d-flex align-center justify-content">
        <v-col 
          cols="5"
          sm="5"
          md="2"
          lg="2"
          xl="2"
        >
          <v-text-field
            v-model="internalCode"
            dense
            disabled
            filled
            label="Codice Interno Prodotto"
            prepend-icon="mdi-code-tags"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        
        <v-col 
          class="ml-2 justify-center align-center" 
          cols="6"
          sm="6"
          md="2"
          lg="2"
          xl="2"
        >
          <v-text-field
            v-model="code"
            :placeholder="codePlaceHolder"
            dense
            filled
            :rules="[max20CharRule, presenceRule]"
            label="Barcode"
            prepend-icon="mdi-barcode"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        
        <v-col 
          :class="{
            'ml-5': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
          }"
          cols="8"
          sm="8"
          md="2"
          lg="2"
          xl="2"
        >
          <v-select
            :rules="[presenceRule]"
            v-model="supplierSelected"
            :items="suppliers"
            item-value="id"
            item-text="businessName"
            :readonly="!!supplierSelectedOutside"
            return-object
            label="Seleziona Fornitore"
            filled
            deletable-chips
            dense
            chips
            small-chips
            hide-details="auto"
            prepend-icon="mdi-account-multiple"
          ></v-select>
        </v-col>
        
        <v-col 
          class="ml-5" 
          cols="2"
          sm="2"
          md="1"
          lg="1"
          xl="1"
        >
          <v-checkbox
            v-model="isInternal"
            label="Uso Interno"
            type="checkbox"
          ></v-checkbox>
        </v-col>
        
        <v-col 
          class="d-flex align-center justify-content"
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
        >
          <v-row>
            <v-col cols="4">
              <v-btn
                class="mx-10 mt-8 back-color-gradient"
                dark
                large
                fab
                @click="toggleAvatarDialog"
                v-show="itemId"
              >
                <v-icon dark>
                  mdi-camera
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-img class="rounded-lg mt-0" v-show="photo" id="photo" :width="180" :height="135" :src="photoUrl"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col 
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="description"
            filled
            dense
            :rules="[max255CharRule, presenceRule]"
            prepend-icon="mdi-clipboard-text"
            label="Descrizione"
            hide-details="auto"
            :placeholder="descriptionPlaceHolder"
          ></v-text-field>
        </v-col>
        
        <!--TODO Marco: quando creo un ordine popolare l'ultima data d'acquisto del item relativo-->
        <v-col 
          cols="6"
          sm="6"
          md="2"
          lg="2"
          xl="2"
        >
          <DatePicker
            v-model="lastPurchaseDate"
            label="Ultima Vendita"
            :readonly = true
            :disabled=true
          ></DatePicker>
        </v-col>
        
        <v-col
          cols="6"
          sm="6"
          md="2"
          lg="2"
          xl="2"
        >
          <DatePicker
            v-model="lastUploadDate"
            label="Ultimo Acquisto"
            :readonly = true
            :disabled=true
          ></DatePicker>
        </v-col>

        <v-col 
          :class="{
            'ml-10': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
          }"
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
        >
          <div class="barcode-container">
            <p
              class="
                d-flex
                align-center
                justify-center
                font-italic font-weight-light
              "
            >
              Codice a Barre
            </p>
            <BarcodeDrawer
              class="barcode d-flex align-center justify-center"
              v-model="barcode"
              label="Barcode"
              filled
              dense
              variant="onlyBarcode"
              hide-details="auto"
            ></BarcodeDrawer>
          </div>
        </v-col>
        
        <v-col 
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
        >
          <v-btn
            class="mx-10 back-color-gradient"
            dark
            large
            fab
            @click="printBarcode"
            v-show="itemId"
          >
            <v-icon dark>
              mdi-barcode
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-subheader class="font-italic font-weight-light py-1"
        >Dati Commerciali</v-subheader
      >
      <v-row no-gutters class="py-2">
        <v-col 
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
        >
          <v-row>
            <v-col 
              :class="{
                'ml-3': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
              }"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="pricePurchase"
                filled
                dense
                prepend-icon="mdi-currency-eur"
                label="Prezzo d'Acquisto Lordo (Senza IVA)"
                type="number"
                :rules="[numberRule]"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            
            <v-col 
              :class="{
                'ml-3': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
              }"
              cols="4"
              sm="4"
              md="2"
              lg="2"
              xl="2"
            >
              <v-text-field
                v-model="discount"
                filled
                dense
                prepend-icon="mdi-file-percent-outline"
                type="number"
                label="Sconto %"
                :rules="[discountRule]"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            
            <v-col 
              :class="{
                'ml-3': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
              }"
              cols="8"
              sm="8"
              md="2"
              lg="2"
              xl="2"
            >
              <v-text-field
                v-model="priceNetPurchase"
                filled
                disabled
                dense
                prepend-icon="mdi-currency-eur"
                label="Netto d'Acquisto (Senza IVA)"
                type="number"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            
            <v-col 
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="priceSelling"
                filled
                dense
                prepend-icon="mdi-currency-eur"
                label="Prezzo di Vendita (Ivato)"
                hide-details="auto"
                :rules="[numberRule]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-subheader class="font-italic font-weight-light"
              >Quantità</v-subheader
            ></v-row
          >
          
          <v-row no-gutters class="py-2">
            <v-col 
              :class="{
                'mr-5': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
              }"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="qtyInStock"
                disabled
                filled
                dense
                prepend-icon="mdi-hexagon-multiple"
                label="Quantità in giacenza"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            
            <v-col 
              :class="{
                'mr-5': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
                'mt-3': !!$vuetify.breakpoint.xs || !!$vuetify.breakpoint.sm,
              }"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="qtyMinStock"
                filled
                dense
                prepend-icon="mdi-hexagon-multiple-outline"
                label="Quantità minima"
                hide-details="auto"
                :rules="[numberRule]"
              ></v-text-field>
            </v-col>
            
            <v-col
              :class="{
                'mr-5': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
                'mt-3': !!$vuetify.breakpoint.xs || !!$vuetify.breakpoint.sm,
              }"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="qtyTidying"
                filled
                dense
                prepend-icon="mdi-hexagon-multiple-outline"
                label="Lotto di riordino"
                hide-details="auto"
                :rules="[numberRule]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        
        <v-col
          :class="{
            'ml-12': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
          }"
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
        >
          <ListItemComponent
            v-if="itemId!=0"
            title="Lista codici associati"
            height="30vh"
            min-height="30vh"
            max-height="30vh"
            color="white"
            :show-header-actions="true"
          >
            <template v-slot:header-actions="{}">
              <v-btn fab small @click="handleAddSubCode" icon
                ><v-icon>mdi-qrcode-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:list-item="{}">
              <v-list-item-subtitle v-for="(item, y) in subCodes" :key="y">
                <v-list-item-subtitle
                  style="font-size: 17px; font-weight: bold"
                  class="d-flex flex-row mb-2"
                >
                  <v-row>
                    <v-col class="mt-2">
                      <div class="d-flex justify-start">
                        <v-btn
                          small
                          @click="setDefaultValueSubCode(item, !item.default)"
                          icon
                          fab
                          ><v-icon :color="item.default ? 'yellow' : ''"
                            >mdi-star</v-icon
                          >
                        </v-btn>
                        <v-btn small @click="deleteSubCode(item)" icon fab
                          ><v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <div class="mt-2 d-flex justify-start">
                          {{ item.code }}
                        </div>
                      </div>
                      <VueBarcode
                        class="d-flex justify-start"
                        v-model="item.code"
                        height="35px"
                        :display-value="false"
                      ></VueBarcode>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-divider></v-divider>
              </v-list-item-subtitle>
            </template>
          </ListItemComponent>
        </v-col>
      </v-row>
      
      <v-row no-gutters>
        <v-subheader class="font-italic font-weight-light"
          >Altre Informazioni</v-subheader
        >
      </v-row>
      
      <v-row no-gutters>
        <v-col
          cols="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          class="px-1"
        >
          <v-autocomplete
            v-model="departmentSelected"
            itemText="name"
            itemValue="id"
            :chips="true"
            :deletableChips="true"
            :smallChips="true"
            label="Reparto"
            :items="departmentItems"
            :rules="[presenceRule]"
            dense
            filled
            hide-details="auto"
          ></v-autocomplete>  
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          class="px-1"
        >
          <v-autocomplete
            v-model="tagCategory"
            itemText="name"
            itemValue="id"
            :chips="true"
            :deletableChips="true"
            :smallChips="true"
            label="Categoria Tag"
            :items="tagsCategoriesItems"
            @input="changeTagCategory"
            dense
            filled
            :rules="[presenceRule]"
            hide-details="auto"
          ></v-autocomplete>  
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          class="px-1"
          :class="{
            'mt-3': !!$vuetify.breakpoint.xs || !!$vuetify.breakpoint.sm,
          }"
        >
          <v-autocomplete
            v-model="tag"
            itemText="description"
            return-object
            :chips="true"
            :deletableChips="true"
            :smallChips="true"
            label="Tag"
            :items="tagsItems"
            :disabled="!tagCategory"
            dense
            filled
            :rules="[presenceRule]"
            hide-details="auto"
          ></v-autocomplete>  
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          class="px-1"
          :class="{
            'mt-3': !!$vuetify.breakpoint.xs || !!$vuetify.breakpoint.sm,
          }"
        >
          <v-autocomplete
            v-model="packageTypeSelected"
            itemText="name"
            itemValue="id"
            :chips="true"
            :deletableChips="true"
            :smallChips="true"
            label="Confezione"
            :items="packageTypeItems"
            dense
            filled
            hide-details="auto"
          ></v-autocomplete>  
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          class="px-1 mt-3"
        >
          <v-autocomplete
            v-model="unitOfMeasureSelected"
            itemText="name"
            itemValue="id"
            :chips="true"
            :deletableChips="true"
            :smallChips="true"
            label="Unità Di Misura"
            :items="unitOfMeasureItems"
            dense
            filled
            hide-details="auto"
          ></v-autocomplete> 
        </v-col>

        <v-col 
          cols="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          class="px-1 mt-3" 
          v-if="!!viewBar.active"
        >
          <v-autocomplete
            v-model="itemTypeSelected"
            itemText="name"
            itemValue="id"
            :chips="true"
            :deletableChips="true"
            :smallChips="true"
            label="Famiglia Bar"
            :items="itemTypesItems"
            dense
            filled
            hide-details="auto"
          ></v-autocomplete>  
        </v-col>
      </v-row>
    </v-form>

    <StandardDialog
      :dialog-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 
        '90vw' : '45vw'"
      dialog-height="calc(180-1)"
      v-if="openSubCodeDialog"
      v-model="openSubCodeDialog"
      title="Associa nuovo codice"
    >
      <div class="d-flex flex-column justify-center align-center">
        <div class="text-h3">{{ newSubCode }}</div>
        <VueBarcode
          v-model="newSubCode"
          height="35px"
          :display-value="false"
        ></VueBarcode>
        <v-text-field
          v-model="newSubCode"
          filled
          class="mt-2 mb-1"
          :rules="[presenceRule]"
          label="Nuovo codice"
          dense
        ></v-text-field>
        <v-alert
          v-if="barcodeAlreadyPresentAlert"
          type="error"
          outlined
        > {{errorMessage}}
        </v-alert>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text @click="associateSubCode">Associa</v-btn>
      </template>
    </StandardDialog>
    <StandardDialog v-model="photoDialog" title="Foto Prodotto" dialog-width="500px" dialog-height="560px">
      <div>
        <div v-show="!cameraLoading" class="ma-0 mt-2">
          <video class="rounded-lg" v-show="!isPhotoTaken && !photoExists" ref="camera" :width="450" :height="337.5" autoplay></video>

          <canvas class="rounded-lg" v-show="isPhotoTaken && !photoExists" id="photoTaken" ref="canvas" :width="450"
            :height="337.5"></canvas>
          
          <v-img class="rounded-lg mb-2" v-show="photoExists" id="photo" :width="450" :height="337.5" :src="photoUrl"></v-img>
        </div>

        <div v-if="!cameraLoading && !errorCamera" class="d-flex justify-center">
          <v-btn color="primary" x-large elevation4 fab @click="takePhoto">
            <a id="downloadPhoto" download="my-photo.jpg"></a>
            <v-icon x-large color="white">{{photoExists ? 'mdi-trash-can' : 'mdi-camera'}}</v-icon>
          </v-btn>
        </div>
      </div>

      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="toggleAvatarDialog"
          >
            Chiudi
          </v-btn>
        <v-btn text color="default" :loading="loadingPhotoUpload" :disabled="!isPhotoTaken" @click="uploadAvatar">Salva</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

import DatePicker from '@/components/common/DatePickerCommon.vue'
import itemsService from "@/services/warehouse/items.service.js";
import suppliersService from "@/services/suppliers/suppliers.service.js";
import BarcodeDrawer from "@/components/common/BarcodeDrawer.vue";
import itemTypesService from "@/services/itemTypes/itemTypes.service.js";
import departmentsService from "@/services/departments/departments.service.js";
import packageTypesService from "@/services/packageTypes/packageTypes.service.js";
import unitOfMeasuresService from "@/services/unitOfMeasures/unitOfMeasures.service.js";
import barcodeService from "@/services/barcode/barcode.service.js";
import ListItemComponent from "@/components/common/ListItemComponent.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import ComboAdder from "@/components/common/ComboAdder.vue"
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import imageLoader from '@/services/images.js'
import tagsService from "@/services/tags/tags.service.js"
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"

export default {
  components: {
    DatePicker,
    BarcodeDrawer,
    ListItemComponent,
    StandardDialog,
    VueBarcode,
    ComboAdder,
    TagsAutocomplete
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    barcodeScanned: {
      type: String,
      default: "",
    },
    itemId: {
      type: Number,
      default: 0,
    },
    supplierSelectedOutside: {
      type: Number,
      default: undefined
    },
    associateBarcode: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      onEditing: this.editing,
      localItem: undefined,
      codePlaceHolder: "Es.. IT00001",
      code: "",
      internalCode: "",
      descriptionPlaceHolder: "Es.. Shampoo",
      description: "",
      barcode: "",
      priceSelling: undefined,
      pricePurchase: undefined,
      priceNetPurchase: undefined,
      discount: 0.0,
      lastPurchaseDate: undefined,
      barcodeAlreadyPresentAlert: false,
      errorMessage: '',

      openSubCodeDialog: false,
      newSubCode: "",
      currentBarcode:{},

      supplierSelected: undefined,
      costOfCenterSelected: undefined,
      packageTypeSelected: undefined,
      unitOfMeasureSelected: undefined,
      itemTypeSelected: undefined,
      departmentSelected: undefined,

      qtyInStock: undefined,
      qtyMinStock: undefined,
      qtyTidying: undefined,
      packageTypeItems: undefined,
      unitOfMeasureItems: undefined,
      departmentItems: undefined,
      tagsCategoriesItems: undefined,
      tagsItems: undefined,

      loading: false,

      isInternal: undefined,
      lastUploadDate: undefined,

      tagCategory: undefined,
      tag: undefined,
      suppliers: [],
      suppliersObject: [],

      subCodes: [],

      costOfCenterItems: [],

      itemTypesItems: [],

      formValid: false,

      photoDialog: false,
      cameraLoading: false,
      isPhotoTaken: false,
      loadingPhotoUpload: false,
      photoExists: false,
      photoUrl: undefined,
      errorCamera: false,
      photo: undefined,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      max20CharRule: (v) => {
        if (v) {
          return v.length <= 20 || "Al massimo 20 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Seleziona un numero maggiore o uguale a zero";
      },
      discountRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
        return "Seleziona uno sconto valido";
      },
      viewBar: undefined,
    };
  },
  barcode: {
    scan(barcode) {
      this.barcode = barcode;
      this.code = this.barcode;
    },
  },
  mounted: function () {
    
    this.loadAutoComplete();

    if (!this.onEditing) this.lastCode();
    
    if (!!this.itemId || !!this.$route.params.id) {
      let id = this.$route.params.id ? this.$route.params.id : this.itemId;
      this.getItem(id);
    } else {
      this.$store.dispatch("warehouse/items/resetFields");
    }

    if(!!this.supplierSelectedOutside){
      this.supplierSelected = this.supplierSelectedOutside 
      this.$store.dispatch("warehouse/items/updateField", {
          field: "supplierId",
          value: this.supplierSelected,
        });
        this.onEditing = false
    } 
    if (!!this.associateBarcode && this.barcodeScanned.length > 0) this.handleAddSubCode()
    else if(this.barcodeScanned.length > 0) this.barcode = this.barcodeScanned
    else this.barcode = "";

    this.$refs.itemForm.validate()
  },
  methods: {
    setDefaultValueSubCode(item, defaultValue) {
      let barcodeUpdate = {
        id: item.id,
        default: defaultValue,
        currentBarcode: this.currentBarcode
      };
      barcodeService
        .updateDefault("items", barcodeUpdate)
        .then(() => {
          let itemUpdate = { id: this.itemId, code: item.code };
          itemsService
            .update({ item: itemUpdate, barcode: item.code })
            .then((value) => {
              this.getItem(this.itemId);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteSubCode(item) {
      if (item.code === this.code)
        alert("Impossibile eliminare il codice padre del prodotto");
      else {
        let confirmed = confirm(
          "Sei sicuro di voler eliminare il sotto codice " + item.code + " ?"
        );

        if (confirmed) {
          barcodeService.delete("items", { id: item.id }).then((value) => {
            this.newSubCode = "";
            this.openSubCodeDialog = false;
            this.getItem(this.itemId);
          });
        }
      }
    },
    associateSubCode() {
      if (!!this.newSubCode && !!this.itemId)
        barcodeService
          .create(this.newSubCode, "items", { id: this.itemId }, false)
          .then((value) => {
            this.newSubCode = "";
            this.openSubCodeDialog = false;
            this.getItem(this.itemId);
          })
        .catch((err) => {
          if(err == 'Code Already Present')
            err = 'Codice a Barre Già Inserito'
          this.errorMessage = err;
          this.barcodeAlreadyPresentAlert = true;
        });
    },
    handleAddSubCode() {
      this.barcodeAlreadyPresentAlert = false
      this.newSubCode = this.barcodeScanned;
      this.openSubCodeDialog = true;
    },
    lastCode() {
      itemsService.getLastCode().then((lastItem) => {
        let progressiveInCode
        let today = new Date()
        if(!!lastItem && Number(lastItem.createdAt.substring(0,4)) == today.getFullYear()){
          progressiveInCode = (Number(lastItem.progressive) + 1).toString()
          while(progressiveInCode.length < 5){
            progressiveInCode = '0' + progressiveInCode
          }
        } 
        else {
          progressiveInCode = '00001'
        }
        this.internalCode = 'PRD' + today.getFullYear() + '-' + progressiveInCode
      });
    },
    fetchItemTypes() {
      return new Promise((resolve,reject) => {
      itemTypesService.list().then((results) => {
        this.itemTypesItems = results.rows
        resolve()
      }).catch((err) => {
          reject(err)
      })
      })
    },
    fetchDepartments() {
      return new Promise((resolve,reject) => {
      departmentsService.list().then((results) => {
        this.departmentItems = results.rows
        resolve()
      }).catch((err) => {
          reject(err)
      })
      })
    },
    fetchPackageTypes() {
      return new Promise((resolve,reject) => {
      packageTypesService.list().then((results) => {
        this.packageTypeItems = results.rows
        resolve()
      }).catch((err) => {
          reject(err)
      })
      })
    },
    fetchUnitOfMeasures() {
      return new Promise((resolve,reject) => {
      unitOfMeasuresService.list().then((results) => {
        this.unitOfMeasureItems = results.rows
        resolve()
      }).catch((err) => {
          reject(err)
      })
      })
    },
    getItem(id) {
      itemsService
        .get(id)
        .then((item) => {
          this.setLocalValue(item);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateField(nameField, value) {
      let tableName = "items";
      this.$store.dispatch("warehouse/" + tableName + "/updateField", {
        field: nameField,
        value: value,
      });
    },
    fetchTags(){
      return new Promise((resolve,reject) => {
        tagsService.list(undefined, 100, { tagCategory: this.tagCategory }).then((results) => {
          this.tagsItems = results.rows
          resolve()
        }).catch((err) => {
            reject(err)
        })
      })
    },
    fetchTagCategories(){
      return new Promise((resolve,reject) => {
        tagsCategoriesService.list(undefined, 2000, { typeCost: true }).then((results) => {
          this.tagsCategoriesItems = results.rows
          resolve()
        }).catch((err) => {
            reject(err)
        })
      })
    },
    changeTagCategory(newVal) {
      this.tag = undefined
      this.fetchTags()
    },
    fetchSuppliers() {
      return new Promise(async (resolve, reject) => {
        suppliersService
          .list()
          .then((results) => {
            this.suppliers = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadAutoComplete() {
      itemsService.costOfCenterOptions().then((costOfCenterList) => {
        this.costOfCenterItems = costOfCenterList;
      });

      this.fetchDepartments();
      this.fetchItemTypes();
      this.fetchPackageTypes();
      this.fetchSuppliers();
      this.fetchUnitOfMeasures();
      this.fetchTags();
      this.fetchTagCategories();
    },
    setLocalValue(item) {
      this.loading = true
      if (!!item) {
        this.subCodes = item.barcodes.filter((item) => !item.default).reverse();
        this.currentBarcode = item.barcodes.filter((item) => item.default)[0]
        this.code = item.code;
        this.internalCode = item.internalCode;
        this.description = item.description;
        this.supplierSelected = Number(item.supplierId);
        this.priceSelling = item.priceSelling;
        this.pricePurchase = item.pricePurchase;
        this.priceNetPurchase = item.priceNetPurchase;
        this.discount = item.discount;
        if(!!item.lastPurchaseDate) this.lastPurchaseDate = new Date(item.lastPurchaseDate);
        if(!!item.lastUploadDate) this.lastUploadDate = new Date(item.lastUploadDate);
        this.costOfCenterSelected = Number(item.costOfCenterId);
        this.packageTypeSelected = Number(item.packageTypeId);
        this.itemTypeSelected = Number(item.itemTypeId);
        this.departmentSelected = Number(item.departmentId);
        this.unitOfMeasureSelected = Number(item.unitOfMeasureId);
        this.qtyInStock = Number(item.qtyInStock);
        this.qtyMinStock = Number(item.qtyMinStock);
        this.qtyTidying = Number(item.qtyTidying);
        this.isInternal = item.isInternal;
        this.barcode = item.code ? item.code : "";
        this.tagCategory = item.tags ? item.tags[0].tagCategoryId : undefined
        this.tag = item.tags ? item.tags[0] : undefined
        this.photo = item.photo
        this.reloadPhoto()
      }
      this.loading = false
    },
    async reloadPhoto(){
      if(this.photo)  
        this.photoUrl = await imageLoader.getImageUrl(this.photo)
    },
    toggleAvatarDialog() {
      if (this.photoDialog) {
        this.isPhotoTaken = false
        this.photoDialog = false
        this.photoExists = false
      } else if(!!this.photo){
        this.photoDialog = true;
        this.photoExists = true
        this.reloadPhoto()
      } else {    
        this.photoDialog = true;
        this.createCameraElement()
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
          this.errorCamera = false
        })
        .catch(error => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
          this.errorCamera = true
        });
    },
    takePhoto() {
      if(this.photoExists){
        this.createCameraElement()
        this.photoExists = false
      } else {
        this.isPhotoTaken = !this.isPhotoTaken;

        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      }
    },
    async uploadAvatar() {
      this.loadingPhotoUpload = true
      const canvas = document.getElementById("photoTaken")
      canvas.download = "AvatarTmp.png"

      var data = new FormData()
      let imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      data.append("photo", imageBlob, 'photo.png')

      let res = await itemsService.uploadPhotoItem(this.itemId, data)
      this.photo = res[0].photo
      
      this.photoUrl = await imageLoader.getImageUrl(this.photo)
      this.reloadPhoto()
      this.loadingPhotoUpload = false
      this.toggleAvatarDialog()
    },
    printBarcode(){
      itemsService.printItem(this.itemId)
    }
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      this.$store.dispatch("warehouse/items/setValidState", newVal);
    },
    itemId(newVal) {
      this.updateField("id", newVal);
    },
    code(newVal) {
      this.barcode = newVal;

      this.updateField("code", newVal);
    },
    description(newVal) {
      this.updateField("description", newVal);
    },
    supplierSelected(newVal) {
      if (newVal) {
        this.$store.dispatch("warehouse/items/updateField", {
          field: "supplierId",
          value: this.onEditing ? newVal : newVal.id,
        });
        this.onEditing = false;
      }
    },
    priceSelling(newVal) {
      this.updateField("priceSelling", newVal);
    },
    pricePurchase(newVal) {
      if (!this.discount) this.priceNetPurchase = newVal;
      else {
        const result = this.pricePurchase * (1 - this.discount / 100);
        if (result > 0) this.priceNetPurchase = parseFloat(result).toFixed(2);
      }
      if (newVal == "") {
        this.priceNetPurchase = "";
      }
      this.updateField("pricePurchase", newVal);
    },
    priceNetPurchase(newVal) {
      this.updateField("priceNetPurchase", newVal);
    },
    discount(newVal) {
      if (!newVal || newVal == "") this.discount = 0;
      if (newVal != undefined && newVal >= 0 && this.pricePurchase >= 0) {
        const result = this.pricePurchase * (1 - this.discount / 100);
        if (result >= 0) this.priceNetPurchase = parseFloat(result).toFixed(2);
      }
      this.updateField("discount", newVal);
    },
    lastPurchaseDate(newVal) {
      this.updateField("lastPurchaseDate", newVal);
    },
    costOfCenterSelected(newVal) {
      this.updateField("costOfCenterId", newVal);
    },
    packageTypeSelected(newVal) {
      this.updateField("packageTypeId", newVal);
    },
    itemTypeSelected(newVal) {
      this.updateField("itemTypeId", newVal);
    },
    unitOfMeasureSelected(newVal) {
      this.updateField("unitOfMeasureId", newVal);
    },
    departmentSelected(newVal) {
      this.updateField("departmentId", newVal);
    },
    isInternal(newVal) {
      this.updateField("isInternal", newVal);
    },
    qtyInStock(newVal) {
      this.updateField("qtyInStock", newVal);
    },
    qtyMinStock(newVal) {
      this.updateField("qtyMinStock", newVal);
    },
    qtyTidying(newVal) {
      this.updateField("qtyTidying", newVal);
    },
    barcode(newVal) {
      this.code = newVal;
      this.updateField("barcode", newVal);
    },
    tag(newVal) {
      this.updateField("tags", [newVal]);
    },
    tagCategory(){
      this.fetchTags()
    }
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    }
  },
};
</script>

<style>
.barcode {
  margin-top: -18px;
}
.barcode-container {
  border: 1px solid grey;
  border-radius: 8px;
}
</style>